/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import NavBar from "../components/TestNavBar";
import SearchBar from "../components/TestSearchBar";

import "./home.css";
import MainHeader from "../components/TestMainHeader";
import InitialQuestion from "../components/TestInitialQuestion";

import { Clipboard, Share, Check, X } from "react-feather";
// import TypeIt from "typeit-react";

function Home() {
  const [questions, setQuestions] = useState("...");
  const [isQuestionAsked, setIsQuestionAsked] = useState(false);
  const [articles, setArticles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedArticleLink, setSelectedArticleLink] = useState("");
  const [loading, setLoading] = useState(false);

  const handleShareClick = (link) => {
    setSelectedArticleLink(link); // Set the link of the article to share
    toggleModal(); // Open the modal
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setShowAlert(true); // Show the alert
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to handle sharing on Facebook
  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      selectedArticleLink
    )}`;
    window.open(url, "_blank");
  };

  // Function to handle sharing on Telegram
  const shareOnTelegram = () => {
    const url = `https://telegram.me/share/url?url=${encodeURIComponent(
      selectedArticleLink
    )}`;
    window.open(url, "_blank");
  };

  // Function to handle sharing on Twitter
  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      selectedArticleLink
    )}`;
    window.open(url, "_blank");
  };

  // Function to handle sharing on WhatsApp
  const shareOnWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      selectedArticleLink
    )}`;
    window.open(url, "_blank");
  };

  // Automatically hide the alert after 2 seconds
  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // Change to 2000ms for 2 seconds
    }
    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }, [showAlert]);

  const handleSearchSubmit = async () => {
    setIsQuestionAsked(true);
  };

  let answer = articles.response;
  if (answer && answer.includes('<br>')) {
      
      answer = answer ? answer.split('<br>') : [];

  }
  
  const containsArabic = (text) => {
    // Regular expression to match Arabic characters
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;

    // Counter for Arabic characters
    let arabicCount = 0;

    // Count Arabic characters in the text
    for (let i = 0; i < text.length; i++) {
        if (arabicRegex.test(text[i])) {
            arabicCount++;
        }
    }

    // Calculate the percentage of Arabic characters
    const arabicPercentage = (arabicCount / text.length) * 100;

    // Check if the percentage is at least 90%
    return arabicPercentage >= 70;
};


  return (
    <div className="h-screen w-screen">
      {showAlert && (
        <div
          className="bg-white text-black px-4 py-3 rounded-lg absolute top-4 left-1/2 -translate-x-1/2 shadow-lg z-30"
          role="alert"
        >
          <span className="flex justify-center items-center">
            <Check size={18} className="mr-2" /> Salin Ke Papan Keratan
          </span>
        </div>
      )}

      <div className="flex flex-col justify-between h-full px-2 md:px-20">
        <div>
          <NavBar isQuestionAsked={isQuestionAsked} questions={questions} />
          {isQuestionAsked && (
            <div className="mt-2 md:mt-10 2xl:px-52">
              <h2 className="bg-primary text-white p-5 rounded-xl text-sm md:text-base md:p-6">
                {questions.charAt(0).toUpperCase() +
                  questions.slice(1).toLowerCase()}
              </h2>

              <div className="card bg-[#e3e3e3] p-5 rounded-xl mt-4 text-sm md:text-base md:px-6">
                <div className="card-body">
                  <div className="card-title">
                    {loading ? (
                      <>
                        <span class="sr-only">Loading...</span>
                        <div class="flex space-x-1 justify-start items-start">
                          <div className="h-1.5 w-1.5 bg-primary rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                          <div className="h-1.5 w-1.5 bg-primary rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                          <div className="h-1.5 w-1.5 bg-primary rounded-full animate-bounce"></div>
                        </div>
                      </> // Loading indicator
                    ) : (
                      <>
                        <p>
                          
                            {/* Check if answer is an array */}
                            {Array.isArray(answer) ? (
                                // If answer is an array, map ove5each element in the 'answer' array
                                answer.map((item, index) => (
                                    <p key={index} className="mb-2">
                                      
                                      {containsArabic(item) ? (
                                          <p className="mt-3 p-5 text-center" style={{ fontSize: '1.5em' }}>{item}</p>
                                      ) : (
                                          item
                                      )}
                                      
                                    </p> 
                                
                                ))
                              ) : (
                                  // If answer is not an array, display a message indicating that there is no content
                                  <p>{articles.response}</p>
                            )}
                        
                        </p>

                        {/* <p className="mb-2 ">Sumber Rujukan :</p> */}
                        {}
                        {answer !== "Maaf, saya tidak dapat menjawab soalan anda." && (
                          <>
                            <p className="mb-2 mt-5">Sumber Rujukan:</p>
                            <ol className="list-decimal list-inside">
                              {Array.isArray(articles.references) && articles.references.length > 0 ? (
                                articles.references.map((article, index) => (
                                  <li key={index} className="mb-2 text-black hover:text-primary cursor-pointer">
                                    <a href={article.link} target="_blank" rel="noreferrer">
                                      {article.title.charAt(0).toUpperCase() +
                                        article.title.slice(1).toLowerCase()}
                                    </a>
                                    <div className="flex mb-4">
                                      <Clipboard
                                        size={16}
                                        className="ml-3"
                                        onClick={() => copyToClipboard(article.link)}
                                      />
                                      <Share
                                        size={16}
                                        className="ml-3"
                                        onClick={() => handleShareClick(article.link)}
                                      />
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <p className="m-0">No data found.</p>
                              )}
                            </ol>
                            {isModalOpen && (
                              <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex justify-center items-center">
                                <div className="bg-white rounded-2xl shadow-lg m-4 max-w-md max-h-full overflow-auto">
                                  <div className="flex justify-between items-center border-b-2 border-[#BEBBBB] p-5">
                                    <h2 className="text-base">Kongsikan rujukan ke media sosial anda</h2>
                                    <button onClick={toggleModal} className="text-black">
                                      <X size={22} />
                                    </button>
                                  </div>
                                  <div className="flex gap-3 px-5 py-8">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/Facebook.png`}
                                      className="w-1/6 p-1 cursor-pointer"
                                      alt="Share on Facebook"
                                      onClick={shareOnFacebook}
                                    />
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/Telegram.png`}
                                      className="w-1/6 p-1 cursor-pointer"
                                      alt="Share on Telegram"
                                      onClick={shareOnTelegram}
                                    />
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/Twitter.png`}
                                      className="w-1/6 p-1 cursor-pointer"
                                      alt="Share on Twitter"
                                      onClick={shareOnTwitter}
                                    />
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/WhatsApp.png`}
                                      className="w-1/6 p-1 cursor-pointer"
                                      alt="Share on WhatsApp"
                                      onClick={shareOnWhatsApp}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        
                        {/* <ol className="list-decimal list-inside ">
                          {Array.isArray(articles.references) &&
                          articles.references.length > 0 ? (
                            articles.references.map((article, index) => (
                              <li
                                key={index}
                                className="mb-2 text-black hover:text-primary cursor-pointer"
                              >
                                <a
                                  href={article.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {article.title.charAt(0).toUpperCase() +
                                    article.title.slice(1).toLowerCase()}
                                </a>
                                <div className="flex mb-4">
                                  <Clipboard
                                    size={16}
                                    className="ml-3"
                                    onClick={() =>
                                      copyToClipboard(article.link)
                                    }
                                  />
                                  <Share
                                    size={16}
                                    className="ml-3"
                                    onClick={() =>
                                      handleShareClick(article.link)
                                    }
                                  />
                                </div>
                              </li>
                            ))
                          ) : (
                            <p className="m-0">No data found.</p>
                          )}

                          {isModalOpen && (

                            <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex justify-center items-center">
                              <div className="bg-white rounded-2xl shadow-lg m-4 max-w-md max-h-full overflow-auto">
                                <div className="flex justify-between items-center border-b-2 border-[#BEBBBB] p-5">
                                  <h2 className="text-base">
                                    Kongsikan rujukan ke media sosial anda
                                  </h2>
                                  <button
                                    onClick={toggleModal}
                                    className="text-black"
                                  >
                                    <X size={22} />
                                  </button>
                                </div>
                                <div className="flex gap-3 px-5 py-8">
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/Facebook.png`}
                                    className="w-1/6 p-1 cursor-pointer"
                                    alt="Share on Facebook"
                                    onClick={shareOnFacebook}
                                  />

                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/Telegram.png`}
                                    className="w-1/6 p-1 cursor-pointer"
                                    alt="Share on Telegram"
                                    onClick={shareOnTelegram}
                                  />

                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/Twitter.png`}
                                    className="w-1/6 p-1 cursor-pointer"
                                    alt="Share on Twitter"
                                    onClick={shareOnTwitter}
                                  />

                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/WhatsApp.png`}
                                    className="w-1/6 p-1 cursor-pointer"
                                    alt="Share on WhatsApp"
                                    onClick={shareOnWhatsApp}
                                  />
                                </div>
                              </div>
                            </div>

                          )}
                        </ol> */}
                        
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!isQuestionAsked && <MainHeader />}

        <section className="2xl:px-52">
          {!isQuestionAsked && (
            <InitialQuestion
              setQuestions={setQuestions}
              onSearchSubmit={handleSearchSubmit}
              setArticles={setArticles}
              questions={questions}
              setLoading={setLoading}
            />
          )}

          <div className="mt-2">
            <SearchBar
              onSearchSubmit={handleSearchSubmit}
              setQuestions={setQuestions}
              setArticles={setArticles}
              isQuestionAsked={isQuestionAsked}
              setLoading={setLoading}
            />
          </div>

          <h6 className="text-xs text-center my-4 md:text-sm">
            Jawapan Ustaz adalah berdasarkan fatwa-fatwa oleh Mufti Negeri dan
            sumber rujukan ulama berautoriti
          </h6>
        </section>
      </div>
    </div>
  );
}

export default Home;
