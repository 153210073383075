/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Menu, Edit } from "react-feather";

function NavBar({ questions, isQuestionAsked }) {
  const [title, setTitle] = useState(questions);
  const [isEditing, setIsEditing] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // Hook to get the navigate function
  const navigate = useNavigate();

  // Event handler that navigates to the /demo route
  const handleDemoClick = (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    navigate("/demo"); // Navigate to the /demo route
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      event.preventDefault();
    }
  };

  // Toggle the visibility of the dropdown menu
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    if (isQuestionAsked) {
      setTitle(questions);
    }
  }, [questions, isQuestionAsked]);

  return (
    <header className="bg-white">
      <div className="flex h-16 items-center justify-between">
        <div className="md:flex md:items-center md:gap-12">
          <a className="block text-primary" href="/">
            <span className="sr-only">Home</span>
            <h1 className="text-xl font-semibold tracking-wide">
              Tanyalah Ustaz <sup className="text-xs text-black">BETA</sup>
            </h1>
          </a>
        </div>

        {isQuestionAsked && (
          <div className="hidden md:block">
            <nav aria-label="Global">
              <ul className="flex items-center gap-0 text-base -ml-[22%]">
                <li>
                  {isEditing ? (
                    <input
                      type="text"
                      value={title}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      onBlur={() => setIsEditing(false)}
                      className="flex items-center gap-2 transition text-[#3B3434] hover:text-primary/75 outline-none "
                      autoFocus
                    />
                  ) : (
                    <a
                      className="flex items-center gap-2 transition text-[#3B3434]  hover:text-primary/75"
                      href="#"
                      onClick={handleClick}
                    >
                      <span className="cursor-text">{title}</span>
                      <Edit size={18} />
                    </a>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        )}

        <div className="flex items-center gap-4 relative">
          <button
            className="rounded-full p-2 text-primary transition hover:text-gray-600/75"
            onClick={toggleDropdown}
          >
            <Menu size={18} />
          </button>

          {isDropdownVisible && (
            <div className="absolute top-10 right-0 w-48 bg-white shadow-md">
              <ul className="py-1 text-gray-700">
                <li>
                  <a
                    href="/donation"
                    className="block px-4 py-2 text-sm hover:bg-gray-200"
                  >
                    Sumbangan
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={handleDemoClick}
                    className="block px-4 py-2 text-sm hover:bg-gray-200"
                  >
                    Demo
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default NavBar;
