import React from "react";

function MainHeader() {
  return (
    <section className="text-start md:text-center">
      <h1 className="text-4xl font-bold md:text-7xl">
        <span className="text-primary">Tanyalah Ustaz</span>
      </h1>
      <h2 className="text-base text-gray pl-2 pr-8 md:text-xl md:mt-4 md:pr-0">
        Apakah kemusykilan agama anda hari ini?
      </h2>
    </section>
  );
}

export default MainHeader;
