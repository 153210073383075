import React from "react";
import NavBar from "../components/NavBar";

function DonationSuccess() {
  return (
    <div className="h-screen w-screen">
      <div className="flex flex-col justify-between h-full px-2 md:px-20">
        <NavBar showTitle={false} />

        <section className="bg-gray-100 p-8 max-w-xl rounded-xl mx-auto">
          <div className="w-full items-center justify-between flex flex-col">
            <img
              src={`${process.env.PUBLIC_URL}/images/success.png`}
              className="w-1/2 pb-10"
              alt="fail icon"
            />
            <h1 className="pb-10 font-bold text-xl">Sumbangan Berjaya!</h1>

            <p className="pb-10 text-center">
              Terima kasih kerana menyokong Tanyalah Ustaz. Sumbangan anda amat
              kami hargai.
            </p>

            <button
              className="rounded-lg text-base bg-primary py-2 px-10 text-white"
              onClick={() => (window.location.href = "/")}
            >
              Selesai
            </button>
          </div>
        </section>
        <section></section>
      </div>
    </div>
  );
}

export default DonationSuccess;
