import React, { useState } from "react";
import NavBar from "../components/NavBar";
import "./donation.css";

function Donation() {
  const [showForm, setShowForm] = useState(false);
  const [donationAmount, setDonationAmount] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDonationClick = (amount) => {
    setDonationAmount(amount);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "name") setName(value);
    else if (id === "email") setEmail(value);
    else if (id === "phone") setPhone(value);
    else if (id === "other-amount") {
      // Only allow numeric input
      if (!isNaN(value) && value >= 0) setDonationAmount(value);
    }
  };

  const donationOptions = [10, 20, 30, 50, 100, 200, 300, 500];

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    setIsSubmitting(true);

    // Check if the donation amount is empty or falsy (e.g., 0, "", null, undefined)
    if (!donationAmount) {
      alert("Sila masukan jumlah.");
      return; // Stop the function from proceeding further
    }

    const apiUrl = "/api/createBill"; // Your server endpoint that calls the external API

    // Prepare the data you need to send
    const billData = {
      billAmount: donationAmount.toString(), // Assuming donationAmount is your amount state variable
      billTo: name,
      billEmail: email,
      billPhone: phone,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(billData),
      });

      const data = await response.json();
      if (data && data.BillCode) {
        // Redirect the user to the payment page with the generated bill code
        window.location.href = `https://toyyibpay.com/${data.BillCode}`;
      } else {
        // Handle the case where no bill code is returned
        console.error("No bill code returned from the API.");
      }
    } catch (error) {
      console.error("Error creating bill:", error);
      // Optionally, handle the error (e.g., show an error message to the user)
    }
  };

  return (
    <div className="h-screen w-fit md:overflow-y-hidden">
      <div className="flex flex-col justify-between h-full px-2 md:px-20">
        <div>
          <NavBar showTitle={false} />

          <section className="">
            <div className="lg:flex lg:h-screen lg:items-center mt-10 md:mt-0">
              <div className="text-center">
                <div className="flex flex-col justify-center text-start gap-4 md:flex-row">
                  <div className="md:w-2/4 md:pr-20 my-auto">
                    <h2 className="text-secondary text-base mb-0 font-bold tracking-wider">
                      SUMBANGAN
                    </h2>
                    <h1 className="text-primary text-4xl font-extrabold mb-4 tracking-wide">
                      Sokong Projek Kami
                    </h1>

                    <p className="text-black text-base">
                      Terima kasih kerana menggunakan TanyalahUstaz.com. Projek
                      ini diwujudkan bagi membantu orang ramai mendapatkan
                      panduan dan rujukan pantas bagi kemusykilan agama
                      berdasarkan fatwa Majlis Agama dan sumber rujukan ulama
                      yang berautoriti. Moga dengan sokongan anda dapat membantu
                      kami menyempurnakan platform ini melalui penterjemahan
                      fatwa-fatwa bahasa Arab. Kami boleh dihubungi di
                      nuropensource@gmail.com
                      <br></br>
                      <br></br>
                      Indahnya Hidup Bersyariat.
                    </p>
                  </div>

                  <div className="bg-gradient rounded-lg p-5 mb-10 h-[22rem] flex flex-col justify-center md:rounded-2xl md:h-[23rem] md:px-10 md:w-2/3 lg:w-1/3">
                    {!showForm && (
                      <>
                        <div className="grid grid-cols-4 gap-1.5 md:gap-3 text-black text-base whitespace-nowrap cursor-pointer text-center">
                          {donationOptions.map((amount) => (
                            <div
                              key={amount}
                              className={`donation-amount ${
                                donationAmount === amount
                                  ? "!bg-black !text-white"
                                  : ""
                              }`}
                              onClick={() => handleDonationClick(amount)}
                            >
                              RM {amount}
                            </div>
                          ))}
                        </div>

                        <input
                          type="text"
                          id="other-amount"
                          value={donationAmount}
                          className="block w-full mt-3 p-3 text-base text-black rounded-lg bg-white outline-none focus:ring-secondary placeholder:text-black md:text-base"
                          placeholder="Jumlah lain"
                          required
                          onChange={handleInputChange}
                          onKeyDown={(e) => {
                            if (
                              !/[\d]+/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />

                        <div className="flex justify-center items-center">
                          <button
                            className="rounded-lg text-base bg-white py-2.5 px-20 mt-3 text-black font-bold"
                            onClick={() => setShowForm(true)}
                          >
                            Seterusnya
                          </button>
                        </div>
                      </>
                    )}
                    {/* Form for entering personal details */}
                    {showForm && (
                      <form className="w-full" onSubmit={handleSubmit}>
                        <div className="flex flex-wrap -mx-3 mb-2 md:mb-6">
                          <div className="w-full px-3 mb-0">
                            <label
                              className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                              htmlFor="name"
                            >
                              Nama
                            </label>
                            <input
                              className="appearance-none block w-full bg-white text-black rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              id="name"
                              type="text"
                              placeholder="John Carter"
                              value={name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                          <div className="w-full px-3 mb-0">
                            <label
                              className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                              htmlFor="email"
                            >
                              E-mel
                            </label>
                            <input
                              className="appearance-none block w-full bg-white text-black rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              id="email"
                              type="email"
                              placeholder="john@gmail.com"
                              value={email}
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                          <div className="w-full px-3 mb-0">
                            <label
                              className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                              htmlFor="phone"
                            >
                              No. Telefon
                            </label>
                            <input
                              className="appearance-none block w-full bg-white text-black rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              id="phone" // Unique id for the phone field
                              type="tel" // Specifies that the input should be a telephone number
                              placeholder="01136190888"
                              value={phone}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="flex gap-2 font-bold">
                          <button
                            className="rounded-lg text-base bg-white py-2.5 text-black w-1/2"
                            onClick={() => setShowForm(false)}
                          >
                            Sebelum
                          </button>
                          <button
                            className="rounded-lg text-base bg-white py-2.5 text-black w-1/2 flex justify-center items-center"
                            type="submit"
                          >
                            {isSubmitting ? (
                              <>
                                <svg
                                  aria-hidden="true"
                                  role="status"
                                  className="inline w-4 h-4 mr-3 text-primary animate-spin"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                Sedang diproses...
                              </>
                            ) : (
                              "Sumbang Sekarang"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Donation;
