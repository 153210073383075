import React, { useState } from "react";
import { Search, ArrowUp } from "react-feather";// Import the Typesense library

function SearchBar({
  onSearchSubmit,
  setQuestions,
  setArticles,
  demoMode,
  isQuestionAsked,
  setLoading,
}) {
  const [inputValue, setInputValue] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const question = inputValue;

    if (question) {
      const questionText = question.trim();
      setInputValue(""); 
      setQuestions(questionText);
      onSearchSubmit(true);
      setLoading(true);  // Set loading to true

      try {
        const live = 'https://test.tanyalahustaz.com/process_input';
        // const live = 'http://127.0.0.1:5000/process_input'
        const response = await fetch(live, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ input_text: questionText })
        });

        const result = await response.json();
        const articles = {
          input_text: result.input_text,
          references: result.references,
          response: result.response
        };

        setArticles(articles);
      } catch (error) {
        console.error("Error fetching articles:", error);
        alert("Fetch error: " + error.message);
        setArticles([]);
      } finally {
        setLoading(false);  // Set loading to false
      }
    } else {
      alert("No question found");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <form
        className="max-w mx-auto"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 items-center ps-3 pointer-events-none hidden md:flex">
            <Search size={16} color="#535050" strokeWidth={1.5} />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 text-sm text-gray rounded-md bg-[#e3e3e3] outline-none focus:ring-secondary placeholder:text-gray/50 md:pl-10
                        md:p-4.5 md:text-base md:rounded-xl"
            placeholder={
              demoMode
                ? "Fungsi carian soalan dinyahkan bagi halaman demo"
                : !isQuestionAsked
                ? "Ustaz, saya nak tanya..."
                : "Ustaz, saya nak tanya lagi..."
            }
            value={inputValue} // Control the input's value with state
            onChange={handleInputChange} // Update state on input change
            disabled={demoMode ? true : false}
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-primary focus:ring-2 focus:outline-none focus:ring-secondary font-medium rounded-sm text-sm p-2
                        md:p-3 md:bottom-2 md:rounded-md"
          >
            <ArrowUp size={16} color="white" strokeWidth={2} />
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
