import React, { useState } from "react";
import { Search, ArrowUp } from "react-feather";
import Typesense from "typesense"; // Import the Typesense library

function SearchBar({
  onSearchSubmit,
  setQuestions,
  setArticles,
  demoMode,
  isQuestionAsked,
}) {
  const [inputValue, setInputValue] = useState("");

  const client = new Typesense.Client({
    nodes: [
      {
        host: 'typesense.tanyalahustaz.com',
        port: "443",
        protocol: "https",
      },
    ],
    apiKey: process.env.REACT_APP_TYPESENSE_APIKEY,
    connectionTimeoutSeconds: 5,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const question = inputValue;
    if (question) {
      setQuestions(question);
      onSearchSubmit(true);

      try {
        const searchParameters = {
          q: question,
          query_by: "title,content",
          per_page: 10,
        };

        const result = await client
          .collections("articles")
          .documents()
          .search(searchParameters);
        if (result && Array.isArray(result.hits) && result.hits.length > 0) {
          const articles = result.hits.map((hit) => hit.document);
          setArticles(articles);
        } else {
          console.error("No articles found matching the criteria");
          setArticles([]);
        }
      } catch (error) {
        console.error("Error searching articles with Typesense:", error);
        setArticles([]);
      }

      setInputValue(""); // Clear the input field
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <form
        className="max-w mx-auto"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 items-center ps-3 pointer-events-none hidden md:flex">
            <Search size={16} color="#535050" strokeWidth={1.5} />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 text-sm text-gray rounded-md bg-[#e3e3e3] outline-none focus:ring-secondary placeholder:text-gray/50 md:pl-10
                        md:p-4.5 md:text-base md:rounded-xl"
            placeholder={
              demoMode
                ? "Fungsi carian soalan dinyahkan bagi halaman demo"
                : !isQuestionAsked
                ? "Ustaz, saya nak tanya..."
                : "Ustaz, saya nak tanya lagi..."
            }
            value={inputValue} // Control the input's value with state
            onChange={handleInputChange} // Update state on input change
            disabled={demoMode ? true : false}
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-primary focus:ring-2 focus:outline-none focus:ring-secondary font-medium rounded-sm text-sm p-2
                        md:p-3 md:bottom-2 md:rounded-md"
          >
            <ArrowUp size={16} color="white" strokeWidth={2} />
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
