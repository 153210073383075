import React from "react";
import NavBar from "../components/NavBar";

function DonationInfo() {
  return (
    <div className="h-screen w-screen">
      <div className="flex flex-col justify-between h-full px-2 md:px-20">
        <NavBar showTitle={false} />

        <section className="bg-gray-100 p-8 max-w-2xl rounded-xl mx-auto">
          <form className="w-full items-center justify-center flex flex-col">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Nama
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="John Carter"
                />
                <p className="hidden text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              </div>

              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  E-mel
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="john@gmail.com"
                />
                <p className="hidden text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              </div>

              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  No. Telefon
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="01136190888"
                />
                <p className="hidden text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              </div>
            </div>

            <button className="rounded-lg text-base bg-primary py-2.5 px-10 text-white">
              Sumbang Sekarang
            </button>
          </form>
        </section>
        <section></section>
      </div>
    </div>
  );
}

export default DonationInfo;
