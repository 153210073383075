import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Donation from "./pages/Donation";
import DonationSuccess from "./pages/DonationSuccess";
import DonationFail from "./pages/DonationFail";
import DonationIfo from "./pages/DonationInfo";
import Payment from "./pages/Payment";
import Demo from "./pages/Demo";
import TestHome from "./test/pages/TestHome";
import TestHomeV2 from "./test-v2/pages/TestHome"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TestHome/>} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/donation" element={<Donation />} />
        <Route path="/donation-info" element={<DonationIfo />} />
        <Route path="/donation-success" element={<DonationSuccess />} />
        <Route path="/donation-fail" element={<DonationFail />} />
        <Route path="/payment" element={<Payment />} />
        {/* <Route path="/test" element={<TestHome />} /> */}
        {/* <Route path="/testv2" element={<TestHomeV2 />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
