/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import SearchBar from "../components/SearchBar";

import "./home.css";
import TypeIt from "typeit-react";

import { Clipboard, Share, Check } from "react-feather";

function Home() {
  const questions = "Apakah hukum pelaburan Amanah Saham Berhad (ASB)";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setShowAlert(true); // Show the alert
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Automatically hide the alert after 2 seconds
  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // Change to 2000ms for 2 seconds
    }
    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }, [showAlert]);

  return (
    <div className="md:h-screen w-98">
      {showAlert && (
        <div
          className="bg-white text-black px-4 py-3 rounded-lg sticky mx-auto w-52 text-sm md:absolute top-4 md:left-1/2 md:-translate-x-1/2 shadow-lg"
          role="alert"
        >
          <span className="flex justify-center items-center">
            <Check size={18} className="mr-2 " /> Salin Ke Papan Keratan
          </span>
        </div>
      )}

      <div className="flex flex-col justify-between h-full px-2 md:px-20">
        <div>
          <NavBar isQuestionAsked={true} questions={questions} />
          <div className="mt-2 md:mt-10 2xl:px-52">
            <h2 className="bg-primary text-white p-5 rounded-xl text-sm md:text-base md:p-6">
              {questions}
            </h2>

            <div className="card bg-[#e3e3e3] p-0 rounded-xl mt-4 text-sm md:text-base md:p-0 md:pr-10">
              <div className="card-body">
                <div className="card-title">
                  <div className="card bg-[#e3e3e3] px-5 py-2 rounded-xl">
                    <div className="card-body">
                      <TypeIt
                        options={{
                          speed: 5,
                          waitUntilVisible: true,
                          lifeLike: true,
                        }}
                      >
                        <div className="card-title">
                          Setelah meneliti fatwa-fatwa daripada negeri-negeri
                          yang telah dikeluarkan, maka hukum pelaburan Amanah
                          Saham Berhad (ASB), Amanah Saham Nasional (ASN), dan
                          seumpamanya kepada orang ramai adalah harus.
                          Justifikasi hukum harus adalah kerana: <br></br>
                          <br></br>
                          <ul className="list-disc list-inside">
                            <li className="mb-2" key={9}>
                              Peratusan pelaburan dalam syarikat patuh syarak
                              telah melepasi 66% mengikut piawaian Suruhanjaya
                              Sekuriti
                            </li>
                            <li className="mb-2" key={10}>
                              Pengharusan ini berdasarkan kaedah fiqhiyyah:
                              "Hukum Berubah Menurut Perubahan 'Illah (sebab)
                            </li>
                            <li className="mb-2" key={11}>
                              Bagi menjaga kepentingan dan maslahah umat Islam
                              serta mengelakkan kemudharatan yang lebih besar
                            </li>
                          </ul>{" "}
                          <br></br>
                          Manakala bagi pihak pengurusan ASB dan ASN iaitu PNB,
                          pelaburan di tempat yang menyalahi syara’ seperti
                          institusi-institusi riba tanpa alasan syar’ie adalah
                          haram dan wajib dihentikan. Usaha ke arah patuh
                          syari’ah secara sepenuhnya wajib dilaksanakan oleh
                          pihak PNB dengan perancangan yang jelas bagi
                          memastikan umat Islam menguasai ekonomi yang halal.
                          Dalam tempoh kekangan memastikan perlaksanaan halal
                          sepenuhnya, pihak PNB hendaklah memaklumkan kepada
                          para pelabur kadar pelaburan yang tidak patuh syari’ah
                          untuk membolehkan pelabur melakukan pembersihan.{" "}
                          <br></br>
                          <br></br>
                          <ol className="list-decimal list-inside ">
                            <li
                              key={1}
                              className="mb-2 text-black hover:text-primary cursor-pointer"
                            >
                              <a
                                href="https://muftiperlis.gov.my/index.php/himpunan-fatwa-negeri/115-fatwa-pelaburan-amanah-saham-bumiputera-asb"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Fatwa Pelaburan Amanah Saham Bumiputera (ASB)
                              </a>

                              <div className="flex mb-4">
                                <Clipboard
                                  size={16}
                                  className="ml-3"
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://muftiperlis.gov.my/index.php/himpunan-fatwa-negeri/115-fatwa-pelaburan-amanah-saham-bumiputera-asb"
                                    )
                                  }
                                />
                                <Share
                                  size={16}
                                  className="ml-3"
                                  onClick={toggleModal}
                                />
                              </div>
                            </li>

                            <li
                              key={2}
                              className="mb-2 text-black hover:text-primary cursor-pointer"
                            >
                              <a
                                href="https://www.muftiselangor.gov.my/2023/10/27/fatwa-pelaburan-permodalan-nasional-berhad-pnb/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Fatwa Pelaburan Permodalan Nasional Berhad (PNB)
                              </a>

                              <div className="flex mb-4">
                                <Clipboard
                                  size={16}
                                  className="ml-3"
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://www.muftiselangor.gov.my/2023/10/27/fatwa-pelaburan-permodalan-nasional-berhad-pnb/"
                                    )
                                  }
                                />
                                <Share
                                  size={16}
                                  className="ml-3"
                                  onClick={toggleModal}
                                />
                              </div>
                            </li>

                            <li
                              key={3}
                              className="mb-2 text-black hover:text-primary cursor-pointer"
                            >
                              <a
                                href="https://mufti.penang.gov.my/index.php/efatwa-2/324-pelaburan-asn-asb-serta-dividen-atau-bonus-yang-diterima"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Pelaburan ASN, ASB serta dividen atau bonus yang
                                diterima
                              </a>

                              <div className="flex mb-4">
                                <Clipboard
                                  size={16}
                                  className="ml-3"
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://mufti.penang.gov.my/index.php/efatwa-2/324-pelaburan-asn-asb-serta-dividen-atau-bonus-yang-diterima"
                                    )
                                  }
                                />
                                <Share
                                  size={16}
                                  className="ml-3"
                                  onClick={toggleModal}
                                />
                              </div>
                            </li>

                            <li
                              key={4}
                              className="mb-2 text-black hover:text-primary cursor-pointer"
                            >
                              <a
                                href="https://muftins.gov.my/fatwa/pelaburan-dalam-asn-asb-dan-seumpamanya/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Pelaburan Dalam ASN, ASB dan Seumpamanya
                              </a>

                              <div className="flex mb-4">
                                <Clipboard
                                  size={16}
                                  className="ml-3"
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://muftins.gov.my/fatwa/pelaburan-dalam-asn-asb-dan-seumpamanya/"
                                    )
                                  }
                                />
                                <Share
                                  size={16}
                                  className="ml-3"
                                  onClick={toggleModal}
                                />
                              </div>
                            </li>

                            <li
                              key={5}
                              className="mb-2 text-black hover:text-primary cursor-pointer"
                            >
                              <a
                                href="https://e-muamalat.islam.gov.my/ms/bahan-ilmiah/laman-hukum/117-pelaburan-dalam-asn-asb-dan-seumpamanya"
                                target="_blank"
                                rel="noreferrer"
                              >
                                e-Muamalat : Pelaburan Dalam ASN, ASB Dan
                                Seumpamanya
                              </a>

                              <div className="flex mb-4">
                                <Clipboard
                                  size={16}
                                  className="ml-3"
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://e-muamalat.islam.gov.my/ms/bahan-ilmiah/laman-hukum/117-pelaburan-dalam-asn-asb-dan-seumpamanya"
                                    )
                                  }
                                />
                                <Share
                                  size={16}
                                  className="ml-3"
                                  onClick={toggleModal}
                                />
                              </div>
                            </li>

                            {isModalOpen && (
                              <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex justify-center items-center">
                                <div className="bg-white rounded-2xl shadow-lg m-4 max-w-md max-h-full overflow-auto">
                                  <div className="flex justify-between items-center border-b-2 p-5">
                                    <h2 className="text-lg">
                                      Kongsikan rujukan ke media sosial anda
                                    </h2>
                                    <button
                                      onClick={toggleModal}
                                      className="text-black"
                                    >
                                      X
                                    </button>
                                  </div>
                                  <div className="mt-4 flex gap-5 p-5 pb-10">
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/Facebook.png`}
                                      className="w-1/6"
                                      alt="media icon"
                                    />

                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/Telegram.png`}
                                      className="w-1/6"
                                      alt="media icon"
                                    />

                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/Twitter.png`}
                                      className="w-1/6"
                                      alt="media icon"
                                    />

                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/WhatsApp.png`}
                                      className="w-1/6"
                                      alt="media icon"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </ol>
                        </div>
                      </TypeIt>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="2xl:px-52">
          <div className="mt-2">
            <SearchBar demoMode={true} />
          </div>

          <h6 className="text-xs text-center my-4 md:text-sm">
            Jawapan Ustaz adalah berdasarkan fatwa-fatwa oleh Mufti Negeri dan
            sumber rujukan ulama berautoriti
          </h6>
        </section>
      </div>
    </div>
  );
}

export default Home;
