import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Payment = () => {
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const statusId = query.get("status_id");
    const billCode = query.get("billcode");

    switch (statusId) {
      case "1":
        navigate("/donation-success");
        break;
      case "2":
      case "3":
        navigate(`/donation-fail?billcode=${billCode}`);
        break;
      default:
        // Handle other statuses or show an error, etc.
        console.log("Unknown status");
    }
  }, [navigate, query]);

  return <div>Loading...</div>;
};

export default Payment;
