/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function InitialQuestion({ setQuestions, onSearchSubmit, setArticles, setLoading }) {
  
  const handleDemoClick = async (event) => {
    event.preventDefault();
    const questionText = event.currentTarget.textContent.trim();
    console.log(questionText);
  
    if (questionText) {
      setQuestions(questionText);
      onSearchSubmit(true);
      setLoading(true);  // Set loading to true

      try {
        // const response = await fetch('http://127.0.0.1:5000/process_input', {
        const response = await fetch('https://test.tanyalahustaz.com/process_input', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ input_text: questionText })
        });

        const result = await response.json();
        const articles = {
          input_text: result.input_text,
          references: result.references,
          response: result.response
        };

        setArticles(articles);
      } catch (error) {
        console.error("Error fetching articles:", error);
        alert("Fetch error: " + error.message);
        setArticles([]);
      } finally {
        setLoading(false);  // Set loading to false
      }
    } else {
      alert("No question found");
    }
  };
  

  return (
    <div className="grid grid-cols-2 gap-1 md:gap-2">
      <div className="initial-question-card " onClick={handleDemoClick}>
        <a href="#">
          <strong>Hukum</strong> <br />
          zakat ke atas bitcoin
        </a>
      </div>
      <div className="initial-question-card" onClick={handleDemoClick}>
        <a href="#">
          <strong>Jumlah</strong> <br />
          bilangan rakaat tarawih
        </a>
      </div>

      <div
        className="initial-question-card hidden md:flex"
        onClick={handleDemoClick}
      >
        <a href="#">
          <strong>Apakah hukum</strong> <br />
          membatalkan puasa ganti
        </a>
      </div>

      <div
        className="initial-question-card hidden md:flex"
        onClick={handleDemoClick}
      >
        <a href="#">
          <strong>Berapakah kadar</strong> <br />
          mengenai fidyah dan perlukah gandakan fidyah
        </a>
      </div>
    </div>
  );
}

export default InitialQuestion;
